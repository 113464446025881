<template>
  <div class="row">
    <div class="col-md-6">
      <div class="row header-info">
        <div class="col-md-12 text-left pl-3">
          <span>&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-user"></i>&nbsp;&nbsp;{{ this.$store.state.auth.user }} [{{ this.$store.state.auth.role }}]</span>
        </div>
      </div>
    </div>
    <div class="col-md-6 float-right">
      <div class="row  header-info">
        <div class="col-md-12 text-right" style="text-align:right">
          <router-link to="/home" class="btn btn-primary" v-show="showHomeButton()"><i class="fa fa-home"></i>&nbsp;&nbsp;Home</router-link>
          <router-link to="/setting" class="btn btn-primary" v-show="showSettingButton()"><i class="fa fa-gears"></i>&nbsp;&nbsp;Ayarlar</router-link>
          &nbsp;
          <button class="btn btn-danger" @click="onLogout()"><i class="fa fa-circle-xmark"></i>&nbsp;&nbsp;Logout</button>
        </div>
      </div>
    </div>
  </div>
  <!--/ End Nav -->
</template>

<script>
import router from '../router';

export default {
  name: "AppHeader",
  methods:{
    showSettingButton(){
      return ((this.$store.state.auth.role=='su') && (this.$route.name=="home"));
    },
    showHomeButton(){
      return (this.$route.name=="setting");
    },
  onLogout(){
      this.$store.commit('auth/CLEARAUTHUSER');
      localStorage.removeItem('neredesinmiv2');
       router.push('/login');

    }
  },
  
};
</script>

<style lang="scss" scoped>
.row{
  max-width: 98% !important;
}

 .header-info{
  margin-top:5px;
  width:96%;
  padding: 5px;
  text-align: left;
  color:#fff;
 }

 .header-info button{
  max-width: 120px;
 }


</style>
