
import router from "../../router";
import nerdesinService from "../../services/nerdesin-service";

export function isValidUser({ dispatch, commit }, { email, password }) {
  nerdesinService.checklogin(email, password).then(response => {
          console.log("ok2"+response+"--");
    if (response != "xxx") {
          console.log("ok");
          console.log(response);
            localStorage.setItem('neredesinmiv2',JSON.stringify(response));
            commit("SETAUTHUSER",response);
            router.push('/home');
      } else {
      router.push('/login');    
    }
    
        })
        .catch(e => {
            console.log(e);
          //console.log(e);
        });
}
