import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import LoginComponent from '../views/Login'
import Home from '../views/Home.vue'
import Settings from '../views/Settings'

const routes= [
  {
      path: '/',
      redirect: {
          name: "login"
      }
  },
  {
      path: "/login",
      name: "login",
      component: LoginComponent
  },
  {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        title: 'Neredeler',
      },
  
  },
  {
    path: '/setting',
    name: 'setting',
    component: Settings,
    meta: {
      title: 'Neredeler',
    },

}

]
/*
  {
      // path: "*",
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: PageNotFound,
      meta: {
        requiresAuth: false
      }
      
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Neredeler',
    },
    
  }
  
]
*/
const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

export default router

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/home');
  }
  next();
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})
/*
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
})*/