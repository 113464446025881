<template>
  <div class="row">
    <div class="col-md-6 leftColumn">
      <div class="row d-flex">
        <div class="col-md-12 text-center">
          <div class="bigButton icerdeButtonTitle">OFİSDE</div>
        </div>
        <div class="col-md-12">
          <div
            class="bigButton icerde"
            v-for="(listItem, index) in listInOffice"
            @click="
              doSetPlace(listItem.POSSTAT, listItem.PRCID, listItem.PRCNAME)
            "
          >
            {{ listItem.PRCNAME }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 rightColumn">
      <div class="row d-flex">
        <div class="col-md-12">
          <div class="bigButton disardaButtonTitle">DIŞARDA</div>
        </div>
        <!--
        <div class="col-md-12">
          <div
            class="bigButton disarda gelmedi"
            v-for="(listItem, index) in listMesaiDisi"
            @click="
              doSetPlace(listItem.POSSTAT, listItem.PRCID, listItem.PRCNAME)
            "
          >
            {{ listItem.PRCNAME }}
            <br />
            <span>MESAİ DIŞI</span>
          </div>
        </div>
      -->
        <div class="col-md-12">
          <div
            class="bigButton disarda"
            v-bind:class="{ active: !listItem.POSSTAT }"
            v-for="(listItem, index) in listOutOfOffice"
            @click="
              doSetPlace(listItem.POSSTAT, listItem.PRCID, listItem.PRCNAME)
            "
          >
            {{ listItem.PRCNAME }}
            <br />
            <span>{{ listItem.PLCNAME }}</span>
            <div class="outTime">{{ writeOutTime(listItem.POSTIME) }}</div>
            <div class="diffTime">{{ writeDiffTime(listItem.POSTIME) }}</div>
          </div>
        </div>

        <div class="col-md-12">
          <div
            class="bigButton disarda gelmedi"
            v-for="(listItem, index) in listMesaiDisi"
            @click="
              doSetPlace(listItem.POSSTAT, listItem.PRCID, listItem.PRCNAME)
            "
          >
            {{ listItem.PRCNAME }}
            <br />
            <span>MESAİ DIŞI</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <confirm-dialogue ref="confirmDialogue" width="400px" height="300px"></confirm-dialogue>-->
  <!--<startwork-dialogue ref="startworkDialogue" width="600px" height="300px"></startwork-dialogue>-->
  <setplace-dialogue
    ref="setplaceDialogue"
    width="800px"
    height="300px"
    @keydown.esc="_closeOnWork"
    @listUpdated="updatedList($event)"
  ></setplace-dialogue>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
//import ConfirmDialogue from "../components/ConfirmDialogue.vue";
import SetplaceDialogue from "../components/SetplaceDialogue.vue";
import moment from "moment";
import nerdesinService from "../services/nerdesin-service";
//import { CLIENT_RENEG_WINDOW } from "tls";
import { returnStatement } from "@babel/types";
const date = new Date();
export default {
  components: { SetplaceDialogue },
  data() {
    return {
      timerUpdateCounter: 0,
      onUpdate: 0,
      fullList: [],
      listMesaiDisi: [],
      listInOffice: [],
      listOutOfOffice: [],
      dateVal: new Date(),
      dateTime: {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDay(),
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
      },
      timer: undefined,
      interval1: null,
      interval2: null,
    };
  },

  created() {
    this.$store.dispatch("positionview/loadViewPositionList");
    this.$store.dispatch("place/loadPlaceList");
    this.$store.dispatch("personel/loadPersonelList");
    this.fullList = this.$store.state.positionview.positionViewList;
    //this.interval1=setInterval(this.getNow, 1000);
    this.interval2 = setInterval(this.refreshData, 10000);
    this.onUpdate = 0;
  },
  destroyed() {
    //clearInterval(this.interval1);
    //clearInterval(this.interval2);
  },

  beforeMount() {
    this.timer = setInterval(this.setDateTime, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    _closeOnWork() {
      this.$refs.setplaceDialogue.close();
    },

    updatedList(listData) {
      this.fullList = listData;
    },

    /*
    async doStartWork(personID,personName) {
  
            const ok = await this.$refs.confirmDialogue.show({
                title: personName,
                message: 'Mesai Başlatılacak ?.',
                okButton: 'Başlat',
                cancelButton: 'Vazgeç',
            })
            const ok = await this.$refs.startworkDialogue.show({
                title: personName,
                message: 'Mesai Başlatılacak ?.',
                okButton: 'Başlat',
                cancelButton: 'Vazgeç',
                personID:personID,
                
            })

            
            if (ok) {
                //alert('You have successfully delete this page.'+personID,personName);
                this.onUpdate=1;
                nerdesinService.setViewPositionStartWork(personID).then(response => {
                  this.fullList=response.data;
                  this.onUpdate=0;
                })
                .catch(e => {
                  console.log(e);
                  //console.log(e);
                  this.onUpdate=0;
                });

            } else {
               // alert('You chose not to delete this page. Doing nothing now.')
            }
        },
*/
    async doSetPlace(curState, personID, personName) {
      const ok = await this.$refs.setplaceDialogue.show({
        title: personName,
        message: "Nereye ?.",
        cancelButton: "Vazgeç",
        personID: personID,
        curState: curState,
      });

      if (ok) {
        // this.refreshData();
        //alert('You have successfully delete this page.'+personID,personName);
        /*            this.onUpdate=1;
                nerdesinService.setViewPositionStartWork(personID).then(response => {
                  this.fullList=response.data;
                  this.onUpdate=0;
                })
                .catch(e => {
                  console.log(e);
                  //console.log(e);
                  this.onUpdate=0;
                });
*/
      } else {
        // alert('You chose not to delete this page. Doing nothing now.')
      }
    },
    setDateTime() {
      const date = new Date();
      this.dateVal = date;
      this.dateTime = {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDay(),
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
      };
    },
    writeOutTime(timeString) {
      return timeString.substring(11);
    },
    writeDiffTime(timeString) {
      //var todayText=this.dateTime.year+"-"+this.dateTime.month+"-"+this.dateTime.day+" "+this.dateTime.hours+":"+this.dateTime.minutes+":"+this.dateTime.seconds;

      //return moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss");
      return moment
        .utc(
          moment(this.dateVal, "YYYY-MM-DD HH:mm:ss").diff(
            moment(timeString, "YYYY-MM-DD HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      //return this.dateTime.hours+":"+this.dateTime.minutes+":"+this.dateTime.seconds;
      //return  new Date().toString();
    },

    refreshData() {
      //if(this.onUpdate) return;
      this.onUpdate = 1;
      nerdesinService
        .getViewPosition()
        .then((response) => {
          console.log("List refreshed");
          //console.log("List refreshed"+response.data);
          this.fullList = response.data;
          this.onUpdate = 0;
        })
        .catch((e) => {
          console.log(e);
          //console.log(e);
          this.onUpdate = 0;
        });
    },

    //...mapActions("positionview"),
    /*      ...mapActions("dealer", ["delDealer"]),        
        delDealer1(itemID){
            this.clearCurrentBayi();
            if(confirm("Kayıt silinsin mi ?")) {
                this.delDealer(itemID);
            }else{
            }
         
        },
*/
    viewPositionListIN() {
      var fullList = this.fullList; //this.$store.state.positionview.positionViewList;
      var pageList = [];

      for (let index = 0; index < fullList.length; index++) {
        if (fullList[index].POSSTAT == 1) {
          pageList.push(fullList[index]);
        }
      }
      this.listInOffice = pageList;
    },
    viewPositionListMesaiDisi() {
      var fullList = this.fullList;
      //var fullList=this.$store.state.positionview.positionViewList;
      var pageList = [];

      for (let index = 0; index < fullList.length; index++) {
        if (fullList[index].POSSTAT == 0) {
          pageList.push(fullList[index]);
        }
      }
      this.listMesaiDisi = pageList;
    },

    viewPositionListOUT() {
      var fullList = this.fullList;
      var pageList = [];

      for (let index = 0; index < fullList.length; index++) {
        if (fullList[index].POSSTAT == 2) {
          pageList.push(fullList[index]);
        }
      }
      //return pageList;
      this.listOutOfOffice = pageList;
    },

    prepList() {
      this.viewPositionListIN();
      this.viewPositionListMesaiDisi();
      this.viewPositionListOUT();
    },
  },

  computed: {},
  mounted() {
    this.refreshData();
    /*axios.get('/api/items/all').then(response => {
        this.title = response.data.title;
    });
    */
  },
  watch: {
    fullList: function (newVal, oldVal) {
      // you can work with a new value here
      console.log("on watch");
      this.prepList();
    },
  },
};
</script>
<style scoped>
.leftColumn {
  border-right: #ccc 3px dashed;
  min-height: 100vh;
}

.outTime {
  display: inline-block;
  position: absolute;
  bottom: 1px;
  left: 5px;
  font-size: 12px;
}

.diffTime {
  display: inline-block;
  position: absolute;
  bottom: 1px;
  right: 5px;
  font-size: 16px;
}
</style>
