
export function SETAUTHUSER(state, val) {
    state.user = val["user"];
    state.role = val["role"];
    state.isAuthenticated=true;
}

export function CLEARAUTHUSER(state) {
    state.user = "";
    state.role = "";
    state.isAuthenticated=false;
}
